import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../diversity/diversity-layout'
import Hero from '../../diversity/diversity-hero'
import ReportSection from '../../diversity/diversity-section'
import SingleImage from '../../diversity/diversity-single-image'
import ImageGrid from '../../components/image-grid'
import BlockQuote from '../../diversity/diversity-blockquote'
// Header goes here so we can pipe some data to it.
import Header from '../../diversity/header'

class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.currentData = this.props.data.currentYear.data
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="Versett – A leading consulting firm specialized in digital transformation, working globally, with offices in Canada and Europe."
          meta={[
            {
              name: 'description',
              content:
                'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
            },
            {
              name: 'keywords',
              content:
                'versett, design, development, consulting, new product, existing product, new company',
            },
            {
              property: 'og:title',
              content:
                'Versett – A leading consulting firm specialized in digital transformation, working globally, with offices in Canada and Europe.',
            },
            {
              property: 'og:description',
              content:
                'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
            },
            {
              property: 'og:image',
              content: 'https://versett.com/images/social-index.jpg',
            },
            { property: 'og:url', content: 'https://versett.com/' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { property: 'og:site_name', content: 'Versett' },
            { name: 'twitter:image:alt', content: 'Versett' },
          ]}
        >
          <html lang="en" className="diversity-report" />
        </Helmet>
        <Header
          reportYear={this.currentData.report_year}
          siteTitle={this.props.data.site.siteMetadata.title}
        />
        <main className="s-container--diversity" id="main-content">
          <Hero
            images={[
              '/images/DI-01.jpg',
              '/images/DI-02.jpg',
              '/images/DI-03.jpg',
              '/images/DI-04.jpg',
            ]}
            animate
          />{' '}
          <section
            className="diversity-report__section d-section"
            id="introduction"
          >
            <h2 className="diversity-report__section__heading">
              <span className="diversity-report-thin-width-column">
                Introduction
              </span>
            </h2>
            <div className="diversity-report__section-body diversity-report-thin-width-column">
              <p>{this.currentData.introduction.text}</p>
              <p className="diversity-report__section__links">
                <a href="https://medium.com/curated-by-versett/diversity-inclusivity-our-mission-8bb9a46fc72a">
                  Read about our mission
                </a>{' '}
                <a href="https://drive.google.com/drive/folders/1uIr-J5Xo9Qrx3ntEV4RejQ-EFPwb2XOO">
                  View our public drive
                </a>
              </p>
            </div>
          </section>
          {this.currentData.body.map((data, index) => {
            switch (data.slice_type) {
              case 'data':
                const previousData = this.props.data.previousYear
                  ? this.props.data.previousYear.data.body.find(item => {
                      if (item.slice_type !== 'data') return false
                      return (
                        item.primary.section_name === data.primary.section_name
                      )
                    }).prevItems
                  : undefined
                return (
                  <ReportSection
                    key={index}
                    currentData={data}
                    previousData={previousData}
                    reportYear={this.currentData.report_year}
                  />
                )

              case 'single_image':
                return <SingleImage key={index} image={data.primary.image} />

              case 'image_grid':
                return (
                  <ImageGrid
                    key={index}
                    direction={data.primary.grid_type}
                    images={data.items}
                  />
                )

              case 'quote':
                return (
                  <BlockQuote key={index} quote={data.primary.quote.html} />
                )

              default:
                return ''
            }
          })}
        </main>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query diversityReport2017Query {
    site {
      siteMetadata {
        title
      }
    }
    previousYears: allPrismicDiversityReport(
      filter: { data: { report_year: { lt: 2017 } } }
      sort: { fields: data___report_year, order: DESC }
      limit: 2
    ) {
      totalCount
      edges {
        node {
          id
          data {
            report_year
            body {
              ... on PrismicDiversityReportDataBodyData {
                slice_type
                primary {
                  section_name
                  data_heading
                }
                prevItems: items {
                  data_point_description
                  data_point_percentage
                }
              }
              ... on PrismicDiversityReportDataBodyNumbers {
                slice_type
                primary {
                  section_name
                  data_heading
                }
                items {
                  data_point_description
                  data_point_percentage
                  make_section_heading
                }
              }
            }
          }
        }
      }
    }
    currentYear: prismicDiversityReport(data: { report_year: { eq: 2017 } }) {
      id
      data {
        report_year
        introduction {
          text
        }
        body {
          __typename
          ... on PrismicDiversityReportDataBodyData {
            slice_type
            primary {
              section_name
              data_heading
              data_body {
                html
              }
            }
            items {
              data_point_description
              data_point_percentage
            }
          }
          ... on PrismicDiversityReportDataBodySingleImage {
            slice_type
            primary {
              image {
                alt
                url
                thumbnails {
                  Mobile {
                    url
                  }
                }
              }
            }
          }
          ... on PrismicDiversityReportDataBodyImageGrid {
            slice_type
            primary {
              grid_type
            }
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicDiversityReportDataBodyQuote {
            slice_type
            primary {
              quote {
                html
              }
            }
          }
        }
      }
    }
  }
`
